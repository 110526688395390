.social_login{
	width:300px;
	display:flex;
	flex-direction:column;
	align-items:center;
	padding-top:20px;
	gap:10px;
}
.scbx_ct1{
	display:flex;
	width:100%;
	justify-content:space-around;
}
.scbx_txt1{
	font-family:'inter';
	color: rgba(120, 120, 120, 1);
	font-size:12px;
	letter-spacing:1px;
}
.sc_bx1:hover{
	scale:1.01;
	transition:0.1s;
}
.sc_bx1{
	width:180px;
	border-radius:5px;
	border:1px solid rgba(218, 218, 218, 0.5);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	height:38px;
}
.google{
	background:url('/img/google.svg');
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center;
}
.lgin_ct{
	width:100%;
	height:100vh;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.lgin_form{
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	gap:20px;
	flex-direction:column;
	width:300px;
	height:350px;
}
.lgin_tt{
	font-size:30px;
	font-family:'Black han sans';
	color:#333;
	text-decoration: none;
}
.lgin_txt{
	padding:10px 5px 10px 5px;
	border-radius:5px;
	font-size:16px;
	border:none;
	background:rgba(218, 218, 218, 0.8);
}
.lgin_btn{
	width:200px;
	font-family:'inter';
	font-size:16px;
	height:40px;
	border:none;
	border-radius:5px;
	color:#fff;
	background:#333;
}
.lgin_btn:hover{
	cursor:pointer;
}
.opt{
	display:flex;
	flex-direction:column;
	gap:10px;
}
.opt_txt{
	font-size:16px;
	font-family:'inter';
	text-decoration:none;
	color:#000;
}
.opt_find{
	display:flex;
	flex-direction:row;
	gap:10px;
}