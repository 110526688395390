.price_ct{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	gap:50px;
}
.collect_sub{
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.5);
	position: fixed;
	top:0;
	left:0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.collect_inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width:500px;
	height:300px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	background: #fff;
	border-radius: 10px;
}
.collect_bx{
	width:95%;
	height:200px;
	display: flex;
	flex-wrap: wrap;
	gap:10px;
	align-items: center;
	justify-content: center;
}
.collect_chs{
	width:100px;
	height:100px;
	border-radius: 10px;
	border:2px solid #ccc;
	color:#ccc;
	font-family: 'Black han sans';
	font-size: '20px';
	display: flex;
	align-items: center;
	justify-content: center;
}
.collect_res{
	width:95%;
	display: flex;
	justify-content: space-around;
}
.collect_price{
	font-family: 'Black han sans';
	color:#000;
	font-size:24px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.collect_btn{
	width:120px;
	height:60px;
	font-size: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	background:#333;
	color:#fff;
	font-family: 'Black han sans';
}
.prc_ct{
	margin-top: 100px;
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	gap:10px;
}
.prc_bx{
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	flex-direction:row;
	width:600px;
	height:100px;
}
.prc_bx:hover{
	cursor:pointer;
}
.pc_txt{
	width:30%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-family:'Black han sans';
	font-size:22px;
	color:#222;
}
.cr_bt{
	scale:1.5;
}
.cr_bt:hover{
	cursor:pointer;
	scale:1.7;
	transition:0.1s;
}
.cr_column{
	display:flex;
	justify-content:center;
	flex-direction:column;
	gap:10px;
}
.cr_row{
	display:flex;
	justify-content:center;
	flex-direction:row;
	gap:10px;
}
.pr_sale{
	color:rgba(255,0,0,0.8);
	font-size:16px;
}
.sale_per{
	color:rgba(51,51,51,0.7);
	font-size:14px;
}
.pc_btn{
	width:10%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-weight:900;
	font-family:'Black han sans';
	font-size:30px;
	text-decoration: none;
	color:#222;
}
.refund_intro{
	width:600px;
}
.refund_txt{
	width:100%;
	text-align:right;
	font-family:'Black han sans';
}
.refund_ser{
	width:100%;
	font-family:'inter';
	font-size:12px;
	color:rgba(105,105,105);
}
@media(max-width:600px){
	.price_tct{
		width:95%;
	}
	.collect_inner{
		width:90%;
		height:50%;
	}
	.collect_bx{
		height:auto;
	}
	.refund_intro{
		width:95%;
	}
	.refund_ser{
		width:95%;
	}
	.prc_bx{
		width:95%;
	}
	.pc_txt{
		font-size:16px;
	}
}