.pop_ctx{
	position:fixed;
	top:0;
	left:0;
	width:100vw;
	height:100%;
	background:rgba(255, 255, 255, 0.5);
	display: flex;
	align-items:start;
	justify-content: center;
	z-index: 99999;
}
.pop_bxt{
	margin-top:50px;
	width:700px;
  height:600px;
	background:#fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display: flex;
  align-items: center;
  z-index: 999999;
  overflow-y: scroll;
  flex-direction: column;
}
.bxt_bxt1{
	width:80%;
	height:200px;
  display: flex;
  flex-direction: row;
}
.bxt1_l{
  flex-grow:1;
}
.bxt1_r{
  flex-grow:1;
  display: flex;
  justify-content: space-around;
}
.bxt_time{
  font-size:12px;
  font-family: 'inter';
  color:#999;
}
.score_bxt{
  height:200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.score_cnt{
  font-family: 'Black han sans';
  color:rgba(255,0,0,0.8);
  font-size:60px;
}
.pop_bxt_title{
	font-family: 'Black han sans';
	font-size:30px;
  margin-top: 20px;
}
.icon_ctt{
  margin-top: 10px;
  display: flex;
  gap:20px;
}
.icon_bxt{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.icon_bxt:hover{
  cursor:pointer;
}
.icon_bxt_tt{
  font-size:12px;
}
.bxt_bxt2{
  width:80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bxt2_tt{
  width:100%;
  font-family: 'Black han sans';
}
.bxt_bxt3{
  margin-top:10px;
  width:80%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.radar_table{
  width:90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.radar_t{
  border:1px solid #ccc;
  height:70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:5px;
  text-align:'center';
  width:100px;
}
.bxt_bxt4{
  margin-top: 50px;
  margin-bottom: 200px;
  width:80%;
  display: flex;
  flex-direction: column;
}
.wrong_lst{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrlst_bx{
  border:1px solid #ccc;
}

@media(max-width:800px){
  .pop_bxt{
    width:90%;
    height:90vh;
  }
  .pop_bxt_title{
    text-align: center;
  }
  .bxt_bxt1{
    flex-direction: column;
    height:auto;
  }
  .wrlst_bx td{
    padding:10px;
  }
  .wrlst_bx{
    font-size:12px;
  }
}