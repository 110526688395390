.brd{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
}
.brd_ct{
	width:100%;
	max-width:1000px;
	flex-direction:column;
}
.brd_tt{
	font-size:24px;
	margin-top:80px;
	font-family:'black han sans';
}
.dss{
	width:100%;
	max-width:1000px;
	height:100px;
	overflow:hidden;
	position: relative;
	align-items:center;
}
.brd_lst:hover{
	cursor:pointer;
}
.wrt{
	width:100%;
}
.wrt_btn{
	float:right;
	padding:5px 10px 5px 10px;
	font-weight:800;
	color:#fff;
	background:#333;
	border:none;
	border-radius:2px;
}
.pagination{
	display:flex;
	justify-content:center;
	align-items:center;
}
ul{
    list-style: none;
	padding: 0;
}
ul.pagination li {
	display: inline-block;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

ul.pagination li a {
    text-decoration: none;
    color: #444;
    font-size: 1rem;
}

ul.pagination li.active a {
    color: white;
}

ul.pagination li.active {
    background-color: #444;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color:#000;
}

.page-selection {
	width: 48px;
    height: 30px;
	color: #444;
}
table,th,td{
	border-collapse:collapse;
}
td{
	padding:20px;
	text-align:center;
}
thead th{
	border-bottom:1px solid black;

}
thead th:nth-child(1) {
  width: 10%;
}
thead th:nth-child(2) {
  width: 50%;
}
thead th:nth-child(3) {
  width: 20%;
}
thead th:nth-child(4) {
  width: 20%;
}
tbody tr:hover{
	background:rgba(240,240,240);
	transition:0.2s;
}
.brdtb_ct{
	margin-top:20px;
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.brd_tb{
	width:800px;
}

@media(max-width:1000px){
	thead th:nth-child(2) {
		width: 40%;
	}
	thead th:nth-child(4) {
		width: 30%;
	}
	.brd_list td{
		font-size: 12px;
		padding:10px;
	}
	.brd_ct{
		max-width:100%;
	}
	.brd_tb{
		width:100%;
	}
	.logo2{
		font-size:30px;
		margin-left:5px;
	}
	.brd_tt{
		font-size:20px;
		margin-left:5px;
	}
	th{
		font-size:12px;
	}
	thead th:nth-child(1) {
		width: 10%;
	}
	thead th:nth-child(2) {
		width: 50%;
	}
	thead th:nth-child(3) {
		width: 20%;
	}
	thead th:nth-child(3) {
		width: 20%;
	}
}