.txt_inputs{
	width:1000px;
	height:400px;
	margin-bottom:50px;
}
.txt_tt{
	margin-bottom:5px;
	font-size:16px;
	font-family:'inter';
}
.brd_ct{
	margin-top:80px;
	width:95%;
}
.edits_tt{
	width:100%;
}
.txt_btns{
	width:80px;
	height:40px;
	margin-left:auto;
	margin-right:0;
	display:flex;
	align-items:center;
	justify-content:center;
	border:none;
	border-radius:5px;
	color:#fff;
	background:#333;
	font-size:16px;
}
.txt_btns:hover{
	cursor:pointer;
}
.tt_cts{
	font-size:24px;
	font-family:'inter';
	font-weight:800;
	margin-bottom:5px;
	display:flex;
	align-items:center;
	gap:10px;
}
.tt_input{
	width:800px;
	font-size:20px;
}

@media(max-width:1000px){
	.tt_input{
		width:80%;
		font-size:16px;
	}
	.tt_cts{
		font-size:16px;
	}
	.txt_inputs{
		width:100%;
		height:300px;
		margin-bottom:100px;
	}
}