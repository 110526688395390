.dpn{
	width:200px;
	height:36px;
}
.cur{
	width:200px;
	padding:7px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	font-family:'inter';
	background:#fff;
	border-radius:5px;
}
.cur_dpn{
	position:absolute;
	border-radius:5px;
	width:214px;
	height:280px;
	overflow:scroll;
	overflow-x:hidden;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	background:#fff;
}
.dpn_key{
	width:200px;
	padding:7px;
	font-family:'inter';
}
.dpn_key:hover{
	background:#D1D1D1;
	transition:0.1s;
}
.ico{
	background:url('/icon/ico.png');
	opacity:0.4;
	width:23px;
	height:23px;
	background-repeat: no-repeat;
	background-size:cover;
	float:right;
	position:relative;
	top:-30px;
	right:-10px;
}
@media(max-width:810px){
	.dpn{
		width:100px;
	}
	.cur{
		width:100px;
	}
	.dpn_key{
		width:100px;
	}
	.cur_dpn{
		width:114px;
	}
}