.chbx_ct{
  display: flex;
	gap:10px;
}
.chbx{
	width:15px;
	height:15px;
}
.chbx_txt{
  font-family:'inter';
	font-size:20px;
}
@media(max-width:810px){
	.chbx_txt{
		font-size:12px;
	}
}