/* src/Slideshow.css */
.slideshow {
  width: 100%;
  max-width: 1000px;
	height:500px;
  margin: auto;
	position:relative;
}
.slide{
	width:100%;
	height:100%;
	position:absolute;
	display:flex;
	align-items:center;
	justify-content:center;
	opacity: 1;
	transition: opacity 1s ease-in-out;
}
.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
@media(max-width:500px){
	.slideshow{
		height:400px;
	}
}