.dn{
	gap:15px;
	width:100%;
	justify-content:center;
}
.dt_desc{
	font-size:18px;
	font-weight:bold;
}
.column3{
	display:flex;
	align-items:center;
	flex-direction:column;
}
.row3{
	display:flex;
	align-items:center;
	flex-direction:row;
}
.header1{
	width:100%;
	max-width:810px;
	justify-content:start;
}
.logo3{
	padding:50px 0 30px 0;
	font-family:'Black han sans';
	font-size:40px;
	letter-spacing:-0.07em;
	text-align:center;
	line-height:0.2em;
	color:#333333;
	font-weight:200;
	text-decoration:None;
}
.dn_txt{
	font-family:'staatliches';
	font-size:150px;
	letter-spacing:1px;
	color:#333333;
	justify-content:center;
}
.moveto{
	width:100%;
	max-width:800px;
}
.footer3{
	width:100%;
	height:100px;
	background:#383843;
}
.caution1{
	font-family:'inter';
}
.mypage{
	color:red;
}
.footer_bx3{
	gap:60px;
	width:100%;
	max-width:800px;
	justify-content:start;
}
.footer_txt3{
	font-family:'Manrope';
	font-size:16px;
	color:#fff;
	line-height:1.5em;
}
.gaps2{
	width:100%;
	height:180px;
}
.mv_txt{
	float:right;
	font-family:'inter';
	font-size:16px;
	text-decoration:None;
	color:#333333;
}
.fq{
	gap:15px;
}
#description{
	resize:none;
	width:300px;
	padding:5px;
	word-break:break-all;
	height:150px;
	border:none;
	font-size:14px;
	border:1px solid rgba(218, 218, 218, 0.9);
	font-family:'inter';
	border-radius:5px;
}
.sbm{
	width:310px;
	height:40px;
	border:none;
	font-size:18px;
	background:#444;
	font-family:'inter';
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	font-weight:800;
	color:#fff;
	border-radius:5px;
}
.str{
	justify-content:space-around;
	width:200px;
}
@media(max-width:810px){
	.dn{
		gap:50px;
	}
	.dt_desc{
		font-size:14px;
	}
	.header1{
		width:90%;
	}
	.footer_bx3{
		width:90%;
	}
	.moveto{
		width:90%;
	}
}