body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav_bx{
	width:100%;
	height:70px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction: column;
	position:fixed;
	top:0;
	z-index:10000;
	background:#fff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.title{
	font-family:'Black han sans';
	font-size:30px;
	letter-spacing:-0.07em;
	text-align:center;
	max-width:500px;
	font-weight:200;
	text-decoration: none;
	margin-right: 10px;
}
.nav_top{
	width:100%;
	max-width: 1000px;
	display: flex;
	align-items: center;
	justify-content: end;
	grid-gap:20px;
	gap:20px;
}
.nav_ct{
	width:100%;
	height:50px;
	max-width:1000px;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.nav_link{
	text-decoration: none;
	font-size:12px;
	color:#666;
}
.nav_cd{
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
}
.nav_credit{
	width:16px;
}
.lg{
	display:flex;
	height: 100%;
	font-family:'inter';
	grid-gap:10px;
	gap:10px;
	align-items: center;
	text-align:left;
	flex-grow:1;
}
.lg_txt{
	font-size:16px;
	font-family:'inter';
	text-decoration:none;
	color:#000;
	height:50px;
	display:flex;
	align-items:center;
	justify-content:center;
}
.cr_txt{
	font-size:16px;
	font-family:'inter';
	text-decoration:none;
	color:#000;
}
.lg_txt:hover{
	scale:1.1;
	transition:0.2s;
}
.nav_tt{
	width:120px;
	text-align:center;
	position:relative;
	padding:5px;
}
.hv_dpn{
	top:50px;
	left:-5px;
	position:absolute;
	width:150px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	flex-direction:column;
	border-radius:5px;
}
.dpn_txt{
	width:100%;
	font-size:14px;
	text-decoration:none;
	color:#000;
	height:30px;
	font-family:'inter';
	border-radius:5px;
	background:#fff;
	display:flex;
	align-items:center;
	justify-content:center;
}
.new_tag{
	color:red;
	position: absolute;
	font-size:10px;
}
.nav_tt .new_tag{
	top:10px;
	left:15px;
}
.side_tt .new_tag{
	top:10px;
	left:50px;
}
.nav_bar{
	width:30px;
	height:30px;
	background:url('/img/nav.png');
	background-size:contain;
	background-repeat: no-repeat;
	display:none;
}
.nav_bar_close{
	width:30px;
	height:30px;
	background:url('/img/nav_close.png');
	background-size:contain;
	background-repeat: no-repeat;
	display:none;
}
.dpn_txt:hover{
	background:rgb(230,230,230);
	transition:0.3s;
}
.nav_side{
	display:none;
	width:200px;
	height:100vh;
	background:#fff;
	position:fixed;
	right:0;
	top:71px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	z-index:10000;
}
.side_tt{
	width:100%;
	height:50px;
	display:flex;
	position: relative;
	align-items:center;
	justify-content:space-around;
	font-family:'inter';
	font-size:16px;
	text-decoration:none;
	color:#333;
}
.side_small{
	font-size:12px;
}
@media(max-width:1000px){
	.lg{
		margin-left:10px;
	}
	.nav_tt{
		width:60px;
		padding:5px;
	}
	.hv_dpn{
		width:60px;
	}
	.dpn_txt{
		font-size:11px;
		border-radius:0;
	}
	.nav_credit{
		width:11px;
	}
	.lg_txt{
		font-size:14px;
	}
	.cr_txt{
		font-size:11px;
	}
}
@media(max-width:500px){
	.title{
		margin-left:10px;
	}
	.lg{
		display:none;
	}
	.nav_tt{
		display:none;
	}
	.nav_ct{
		justify-content:space-between;
	}
	.nav_bar{
		display:block;
		margin-right:10px;
	}
	.nav_bar_close{
		display:block;
		margin-right:10px;
	}
}
@font-face {
  font-family: 'black han sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/BlackHanSans.cfb4e44f.ttf);
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Inter.32204736.ttf);
}
@font-face {
  font-family: 'manrope';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Manrope.cf98436d.ttf);
}
@font-face {
  font-family: 'NanumMyeongjo';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/NanumMyeongjo-Regular.efdc1f63.ttf);
}
@font-face {
  font-family: 'staatliches';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Staatliches.0b1946ea.ttf);
}
.tttt{
	position:relative;
}
.prb_bt{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top:100px;
	margin-bottom:100px;
}
.prb_bt_txt{
	font-size:40px;
	font-family: 'Black han sans';
}
.prb_go{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width:100%;
	grid-gap:10px;
	gap:10px;
}
.prb_item{
	width:160px;
	height:160px;
	background:none;
	border-radius: 10px;
	border:2px solid #333;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color:#333;
	font-family: 'Black han sans';
	font-size:30px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.prb_item_txt{
	margin-top:10px;
	width:160px;
	font-family: 'Black han sans';

}
.hero {
	display:flex;
	padding-top: 100px;
	align-items:center;
	justify-content:center;
	flex-direction: column;
	width:100%;
}
.slide1{
	width:100%;
	justify-content:space-between;
}
.slide1_txt{
	width:50%;
	font-family:'NanumMyeongjo';
	font-weight:800;
	font-size:24px;

}
.slide1_img{
	width:30%;
	box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
	display:flex;
	align-items:center;
	justify-content:center;
}
@-webkit-keyframes fall{
	0%{
		-webkit-transform:translateY(0);
		        transform:translateY(0);
	}
	95%{
		-webkit-transform:translateY(40px);
		        transform:translateY(40px);
	}
	100%{
		-webkit-transform:translateY(45px);
		        transform:translateY(45px);
	}
}
@keyframes fall{
	0%{
		-webkit-transform:translateY(0);
		        transform:translateY(0);
	}
	95%{
		-webkit-transform:translateY(40px);
		        transform:translateY(40px);
	}
	100%{
		-webkit-transform:translateY(45px);
		        transform:translateY(45px);
	}
}
.slide2{
	width:100%;
	justify-content:space-between;
}
.slide2_img{
	display:flex;
	align-items:center;
	justify-content:center;
	width:50%;
	-webkit-transform:translateY(40px);
	        transform:translateY(40px);
	box-shadow: 0, 0, rgba(0, 0, 0, 0.09) 0px 8px 4px,0;
	-webkit-animation:fall 0.5s ease-in;
	        animation:fall 0.5s ease-in;
}
.slide3_img{
	width:500px;
	position:relative;
	height:100px;
}
@-webkit-keyframes up{
	 to {
    opacity: 1; /* Final state visible */
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Final position at original spot */
  }
}
@keyframes up{
	 to {
    opacity: 1; /* Final state visible */
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Final position at original spot */
  }
}
.slide_go{
	margin-top:30px;
	width:100px;
	height:40px;
	border:none;
	border-radius: 5px;
	background: #333;
	color:#fff;
	text-shadow: none;
	display: flex;
	font-family: 'Black han sans';
	font-size:16px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}
.slide3_de{
	border:1px solid black;
	-webkit-animation:up 1s forwards;
	        animation:up 1s forwards;
	opacity:0;
	position:absolute;
}
.slide3_de:nth-child(1){
	top:-150px;
	left:0;
}
.slide3_de:nth-child(2){
	top:-50px;
	left:150px;
	-webkit-animation-delay:0.5s;
	        animation-delay:0.5s;
}
.slide3_de:nth-child(3){
	top:0px;
	left:50px;
	-webkit-animation-delay:1s;
	        animation-delay:1s;
}
.slide4{
	width:100%;
	justify-content:space-between;
}
.hero2{
	display:flex;
	align-items:center;
	position:relative;
	justify-content:center;
	width:100%;
	height:auto;
	min-height:300px;
	opacity:0;
	transition: opacity 0.7s ease-out;
}
.effect7{
	-webkit-transform: translateY(20px);
	        transform: translateY(20px); /* 오른쪽에서 왼쪽으로 */
  transition: opacity 0.7s ease-out, -webkit-transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out, -webkit-transform 0.7s ease-out;
}
.yearly{
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
}
.scr_b{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
}
.xscroll{
	display:flex;
	flex-direction:row;
	align-items:center;
	width:100%;
	height:60px;
	padding:10px;
	overflow-x:auto;
	overflow-y:hidden;
	white-space: nowrap;
	background:rgba(0,0,0,0.1);
	grid-gap:10px;
	gap:10px;
}
.xscroll::-webkit-scrollbar{
	height:10px;
}
.xscroll::-webkit-scrollbar-thumb{
	background:#333;
	border-radius:10px;
}
.xscroll::-webkit-scrollbar-button{
	display:none;
}
.new_txt{
	color:red;
	font-size:10px;
}
.prb_box{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	font-family:'inter';
	font-weight:800;
	text-decoration:none;
	width:100px;
	height:60px;
	background:#fff;
	border:2px solid #333;
	border-radius:5px;
}
.prb_box:hover{
	scale:1.05;
	transition:0.2s;
}
.yearly_title{
	font-size:18px;
	color:#333;
}
.yearly_day{
	font-size:12px;
	color:#555;
}
.column1{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	align-items: center;
}
.ds1{
	width:100%;
	max-width:1000px;
	height:300px;
	overflow:hidden;
	position: relative;
	align-items:center;
}
.review_tt{
	position:absolute;
	top:45%;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
}
.review {
  position: absolute;
	width:1500px;
  display: inline-block;
  white-space: nowrap;
}
.review-content{
	display:flex;
	justify-content:space-between;
	align-items:center;
	font-family:'NanumMyeongjo';
	font-size:12px;
}
@-webkit-keyframes moveLeftToRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
  }
}
@keyframes moveLeftToRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
  }
}
@-webkit-keyframes moveRightToLeft {
  0% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes moveRightToLeft {
  0% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.review:nth-child(1) {
	top:70px;
	left:-500px;
	-webkit-animation: moveLeftToRight 20s linear infinite;
	        animation: moveLeftToRight 20s linear infinite;
}
.review:nth-child(2) {
	top:180px;
	right:500px;
  -webkit-animation: moveRightToLeft 20s linear infinite;
          animation: moveRightToLeft 20s linear infinite;
}
.review:nth-child(3) {
	top:210px;
	left:-500px;
  -webkit-animation: moveLeftToRight 20s linear infinite;
          animation: moveLeftToRight 20s linear infinite;
}
.review:nth-child(4) {
	top:270px;
	right:500px;
	-webkit-animation: moveRightToLeft 20s linear infinite;
	        animation: moveRightToLeft 20s linear infinite;
}
.row1{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:row;
}
.t1{
	font-size:22px;
	margin-bottom:10px;
}
.t2{
	font-size:19px;
}
.desc{
	text-align:center;
	width:100%;
	line-height:1.2em;
	font-family:'inter';
	font-weight:700;
}
.bx_desc{
	font-size:48px;
	width:100%;
	line-height:1.9em;
	font-family:'staatliches';
	color:#727288;
}
.bx_container{
	grid-gap:10px;
	gap:10px;
	width:100%;
	justify-content:space-between;
}
.bx{
	width:25%;
	height:200px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	-webkit-text-decoration:None;
	        text-decoration:None;
	background:#333;
}
.bx:hover{
	-webkit-transform:scale(1.05);
	        transform:scale(1.05);
	transition:0.2s;
}
.bx_title{
	font-family:'Black han sans';
	font-size:50px;
	color:#fff;
	line-height:1em;
}
.bx_title_desc{
	font-family:'Black han sans';
	font-size:20px;
	color:#fff;
	line-height:0.7em;
}


.last_text{
	width:100%;
	height:300px;
}
.ds3{
	width:100%;
	max-width:1000px;
	justify-content:space-between;
	opacity:0;
	transition: opacity 0.7s ease-out;
}
.effect0{
	-webkit-transform: translateY(20px);
	        transform: translateY(20px);
  transition: opacity 0.7s ease-out, -webkit-transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out, -webkit-transform 0.7s ease-out;
}
.lt_title{
	height:100%;
	font-family:'NanumMyeongjo';
	font-weight:600;
	font-size:40px;
	line-height:1.2em;
	width:460px;
}
.lt_desc_smal{
	font-family:'NanumMyeongjo';
	font-size:16px;
	line-height:1.5em;
}
.lt_desc{
	font-family:'NanumMyeongjo';
	font-size:16px;
	line-height:1.5em;
}
.faq{
	width:100%;
	margin-top:50px;
	height:auto;
	justify-content:start;

}
.ds4{
	opacity:0;
	width:100%;
	max-width:1000px;
	grid-gap:60px;
	gap:60px;
	transition: opacity 0.7s ease-out;
}
.effect5{
	-webkit-transform: translateY(20px);
	        transform: translateY(20px);
  transition: opacity 0.7s ease-out, -webkit-transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out, -webkit-transform 0.7s ease-out;
}
.faq_txt{
	font-family:'inter';
	font-weight:700;
	font-size:50px;
	letter-spacing:-2.6px;
	line-height:1.2em;
}
.arcodion{
	font-family:'inter';
	width:100%;
	font-size:16px;
	font-weight:600;
	line-height:1.5em;
	background:#F2F2F2;
	border-radius:20px;
}
.arc{
	padding:20px 0 20px 40px;
	transition: 0.4s;
}
.arc_txt{
	color:#666666;
	padding:0 20px 20px 40px;
	transition: max-height 0.2s ease-out;
}
.contact{
	opacity:0;
	width:100%;
	height:500px;
	padding-bottom:100px;
	grid-gap:60px;
	gap:60px;
	transition: opacity 0.7s ease-out;
}
.effect6{
	-webkit-transform: translateY(20px);
	        transform: translateY(20px);
  transition: opacity 0.7s ease-out, -webkit-transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out, -webkit-transform 0.7s ease-out;
}
.visible {
	opacity: 1;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
}
.cont_txt{
	font-family:'inter';
	font-weight:700;
	font-size:50px;
	line-height:1.2em;
	letter-spacing:-2.1px;
}
.cont_form{
	width:100%;
	max-width:540px;
	grid-gap:15px;
	gap:15px;
}
.ipt{
	width:510px;
	padding:15px;
	border-radius:8px;
	background:#F2F2F2;
	border:None;
	font-family:'inter';
	font-size:16px;
}
.send{
	width:100%;
	padding:15px;
	border:none;
	border-radius:8px;
	color:#fff;
	font-family:'inter';
	font-size:16px;
}

@media(max-width:1200px){
	.ds1{
		max-width:810px;
	}
	.m_nav{
		max-width:810px;
	}
	.ds2{
		max-width:810px;
	}
	.ds3{
		max-width:810px;
		flex-direction:column;
		grid-gap:20px;
		gap:20px;
		align-items:start;
	}
	.lt_desc{
		width:810px;
	}
	.ds4{
		max-width:810px;
	}
}
@media(max-width:810px){
	.hero2{
		min-height:250px;
		margin-bottom:50px;
	}
	.hero{
		margin-top:50px;
	}
	.prb_item{
		width:150px;
		height:150px;
		font-size:30px;
	}
	.prb_item_txt{
		width:150px;
		font-size:16px;
	}
	.arcodion{
		font-size:14px;
	}
	.ft_desc{
		font-size:14px;
	}
	.ft_title{
		font-size:30px;
	}
	.m_nav{
		width:90%;
	}
	.ds1{
		width:90%;
	}
	.ds2{
		width:80%;
		grid-gap:20px;
		gap:20px;
	}
	.img_div{
		width:100%;
		height:200px;
	}
	.ft_content{
		width:100%;
		grid-gap:10px;
		gap:10px;
	}
	.ds3{
		width:90%;
	}
	.send{
		width:90%;
	}
	.ds4{
		width:90%;
	}
	.lt_desc_smal{
		width:100%;
		font-size:12px;
	}
	.lt_desc{
		width:100%;
		font-size:14px;
	}
	.cont_form{
		width:90%;
	}
	.ipt{
		width:80%;
	}

	.contact{
		grid-gap:30px;
		gap:30px;
	}
	.lt_title{
		width:100%;
		font-size:25px;
	}
	.t1{
		font-size:16px;
	}
	.t2{
		font-size:14px;
	}
	.bx_container{
		display:grid;
		grid-template-columns: 1fr 1fr;
	}
	.bx{
		width:100%;
		height:150px;
	}
	.bx_title{
		font-size:40px;
	}
	.bx_desc{
		font-size:30px;
	}
	.slide1{
		flex-direction:column-reverse;
		grid-gap:40px;
		gap:40px;
	}
	.slide1_txt{
		width:95%;
		font-size:18px;
	}
	.slide1_img{
		width:200px;
	}
	.slide2{
		flex-direction:column-reverse;
	}
	.slide3_img{
		width:95%;
	}
	.slide3_de{
		width:100px;
	}
	.slide3_de:nth-child(1){
		top:-110px;
		left:80px;
	}
	.slide3_de:nth-child(2){
		top:-70px;
		left:160px;
	}
	.slide3_de:nth-child(3){
		top:-50px;
		left:110px;
	}
	.slide4{
		flex-direction:column-reverse;
		grid-gap:10px;
		gap:10px;
	}
}
/* src/Slideshow.css */
.slideshow {
  width: 100%;
  max-width: 1000px;
	height:500px;
  margin: auto;
	position:relative;
}
.slide{
	width:100%;
	height:100%;
	position:absolute;
	display:flex;
	align-items:center;
	justify-content:center;
	opacity: 1;
	transition: opacity 1s ease-in-out;
}
.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
@media(max-width:500px){
	.slideshow{
		height:400px;
	}
}
.footer{
	width:100%;
	height:100px;
	background:#383843;
	position:absolute;
	bottom:0;
}
.footer_bx{
	width:100%;
	max-width:1000px;
	justify-content:start;
}
.footer_txt{
	font-family:'Manrope';
	font-size:12px;
	color:rgba(255,255,255,0.5);
	line-height:1.5em;
}
@media(max-width:1200px){
	.footer_bx{
		max-width:810px;
	}
}
@media(max-width:810px){
	.footer_bx{
		width:90%;
	}
	.footer{
		height:120px;
	}
}
.column2{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
}
.row2{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:row;
}
.prb{
	grid-gap:50px;
	gap:50px;
	width:100%;
	height:100vh;
	position:relative;
}
.header2{
	width:100%;
	max-width:800px;
	justify-content:start;
	margin-top:50px;
}
.logo2{
	padding:50px 0 30px 0;
	font-family:'Black han sans';
	font-size:40px;
	letter-spacing:-0.07em;
	text-align:center;
	line-height:0.2em;
	color:#333333;
	font-weight:200;
	-webkit-text-decoration:None;
	        text-decoration:None;
}
.selector{
	margin-top:100px;
	width:100%;
	max-width:800px;
	margin-bottom:100px;
}
.pb_ct{
	width:100%;
	justify-content:space-between;
}
.pb_bx{
	width:25%;
	height:80px;
	font-family:'black han sans';
	color:#222;
	font-size:30px;
	-webkit-text-decoration:None;
	        text-decoration:None;
}
.pb_st{
	justify-content:space-around;
	width:100%;
	max-width:800px;
	height:400px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
}
.st_title{
	font-family:'inter';
	font-size:20px;
	color:#333;
	font-weight:700;
	text-align:center;
	width:85%;
}
.st_ct{
	display:flex;
	align-items:center;
	flex-direction:row;
	width:90%;
	justify-content:start;
	grid-gap:30px;
	gap:30px;
}
.st_pt{
	display:flex;
	align-items:center;
	flex-direction:row;
	width:90%;
	justify-content:start;
	grid-gap:30px;
	gap:30px;
}
.st_txt{
	font-family:'inter';
	font-size:20px;
	color:#333;
	font-weight:700;
}
.btn_ct{
	justify-content:end;
}
.dup_chx{
	width:15px;
	height:15px;
}
.st_btn{
	font-family:'black han sans';
	font-size:18px;
	border-radius:5px;
	font-weight:200;
	padding:10px;
	width:80px;
	color:#fff;
	border:None;
}
.st_btn:hover{
	cursor:pointer;
}
.st_btn_dt{
	font-size:12px;
}
.pop{
	font-size:12px;
	color:red;
}
.chbx_ct{
	grid-gap:10px;
	gap:10px;
}
.chbx{
	width:15px;
	height:15px;
}
.chbx_txts{
	font-family:'inter';
	font-size:20px;
}
.gaps{
	width:100%;
	height:0;
}
.footer2{
	width:100%;
	height:100px;
	background:#383843;
}
.footer_bx2{
	grid-gap:5px;
	gap:5px;
	width:100%;
	max-width:800px;
	justify-content:start;
}
.footer_txt2{
	font-family:'Manrope';
	font-size:12px;
	color:rgba(255,255,255,0.5);
	line-height:1.5em;
}
.caution{
	color:red;
}
@media(max-width:395px){
	.pb_bx{
		font-size:26px;
	}
}
@media(max-width:810px){
	.prb{
		grid-gap:30px;
		gap:30px;
		height:105vh;
	}
	.btn_ct{
		margin-top:40px;
	}
	.header2{
		width:90%;
	}
	.chbx_ct{
		grid-gap:0;
		gap:0;
	}
	.logo2{
		padding-top:30px;
	}
	.st_title{
		font-size:16px;
		margin-bottom:20px;
	}
	.st_txt{
		font-size:16px;
	}
	.gaps{
		height:150px;
	}
	.footer_bx2{
		width:90%;
	}
	.chbx_txts{
		font-size:12px;
	}
	.st_ct{
		grid-gap:10px;
		gap:10px;
	}
	.st_pt{
		grid-gap:30px;
		gap:30px;
	}
}

.dpn{
	width:200px;
	height:36px;
}
.cur{
	width:200px;
	padding:7px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	font-family:'inter';
	background:#fff;
	border-radius:5px;
}
.cur_dpn{
	position:absolute;
	border-radius:5px;
	width:214px;
	height:280px;
	overflow:scroll;
	overflow-x:hidden;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	background:#fff;
}
.dpn_key{
	width:200px;
	padding:7px;
	font-family:'inter';
}
.dpn_key:hover{
	background:#D1D1D1;
	transition:0.1s;
}
.ico{
	background:url('/icon/ico.png');
	opacity:0.4;
	width:23px;
	height:23px;
	background-repeat: no-repeat;
	background-size:cover;
	float:right;
	position:relative;
	top:-30px;
	right:-10px;
}
@media(max-width:810px){
	.dpn{
		width:100px;
	}
	.cur{
		width:100px;
	}
	.dpn_key{
		width:100px;
	}
	.cur_dpn{
		width:114px;
	}
}
.bgs{
	position:absolute;
	width:100vw;
	height:100vh;
	background:rgba(255,255,255,0.4);
	display:flex;
	align-items:center;
	justify-content:center;
}
.chbx_ct{
  display: flex;
	grid-gap:10px;
	gap:10px;
}
.chbx{
	width:15px;
	height:15px;
}
.chbx_txt{
  font-family:'inter';
	font-size:20px;
}
@media(max-width:810px){
	.chbx_txt{
		font-size:12px;
	}
}
.dn{
	grid-gap:15px;
	gap:15px;
	width:100%;
	justify-content:center;
}
.dt_desc{
	font-size:18px;
	font-weight:bold;
}
.column3{
	display:flex;
	align-items:center;
	flex-direction:column;
}
.row3{
	display:flex;
	align-items:center;
	flex-direction:row;
}
.header1{
	width:100%;
	max-width:810px;
	justify-content:start;
}
.logo3{
	padding:50px 0 30px 0;
	font-family:'Black han sans';
	font-size:40px;
	letter-spacing:-0.07em;
	text-align:center;
	line-height:0.2em;
	color:#333333;
	font-weight:200;
	-webkit-text-decoration:None;
	        text-decoration:None;
}
.dn_txt{
	font-family:'staatliches';
	font-size:150px;
	letter-spacing:1px;
	color:#333333;
	justify-content:center;
}
.moveto{
	width:100%;
	max-width:800px;
}
.footer3{
	width:100%;
	height:100px;
	background:#383843;
}
.caution1{
	font-family:'inter';
}
.mypage{
	color:red;
}
.footer_bx3{
	grid-gap:60px;
	gap:60px;
	width:100%;
	max-width:800px;
	justify-content:start;
}
.footer_txt3{
	font-family:'Manrope';
	font-size:16px;
	color:#fff;
	line-height:1.5em;
}
.gaps2{
	width:100%;
	height:180px;
}
.mv_txt{
	float:right;
	font-family:'inter';
	font-size:16px;
	-webkit-text-decoration:None;
	        text-decoration:None;
	color:#333333;
}
.fq{
	grid-gap:15px;
	gap:15px;
}
#description{
	resize:none;
	width:300px;
	padding:5px;
	word-break:break-all;
	height:150px;
	border:none;
	font-size:14px;
	border:1px solid rgba(218, 218, 218, 0.9);
	font-family:'inter';
	border-radius:5px;
}
.sbm{
	width:310px;
	height:40px;
	border:none;
	font-size:18px;
	background:#444;
	font-family:'inter';
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	font-weight:800;
	color:#fff;
	border-radius:5px;
}
.str{
	justify-content:space-around;
	width:200px;
}
@media(max-width:810px){
	.dn{
		grid-gap:50px;
		gap:50px;
	}
	.dt_desc{
		font-size:14px;
	}
	.header1{
		width:90%;
	}
	.footer_bx3{
		width:90%;
	}
	.moveto{
		width:90%;
	}
}
.social_login{
	width:300px;
	display:flex;
	flex-direction:column;
	align-items:center;
	padding-top:20px;
	grid-gap:10px;
	gap:10px;
}
.scbx_ct1{
	display:flex;
	width:100%;
	justify-content:space-around;
}
.scbx_txt1{
	font-family:'inter';
	color: rgba(120, 120, 120, 1);
	font-size:12px;
	letter-spacing:1px;
}
.sc_bx1:hover{
	scale:1.01;
	transition:0.1s;
}
.sc_bx1{
	width:180px;
	border-radius:5px;
	border:1px solid rgba(218, 218, 218, 0.5);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	height:38px;
}
.google{
	background:url('/img/google.svg');
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center;
}
.lgin_ct{
	width:100%;
	height:100vh;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.lgin_form{
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	grid-gap:20px;
	gap:20px;
	flex-direction:column;
	width:300px;
	height:350px;
}
.lgin_tt{
	font-size:30px;
	font-family:'Black han sans';
	color:#333;
	text-decoration: none;
}
.lgin_txt{
	padding:10px 5px 10px 5px;
	border-radius:5px;
	font-size:16px;
	border:none;
	background:rgba(218, 218, 218, 0.8);
}
.lgin_btn{
	width:200px;
	font-family:'inter';
	font-size:16px;
	height:40px;
	border:none;
	border-radius:5px;
	color:#fff;
	background:#333;
}
.lgin_btn:hover{
	cursor:pointer;
}
.opt{
	display:flex;
	flex-direction:column;
	grid-gap:10px;
	gap:10px;
}
.opt_txt{
	font-size:16px;
	font-family:'inter';
	text-decoration:none;
	color:#000;
}
.opt_find{
	display:flex;
	flex-direction:row;
	grid-gap:10px;
	gap:10px;
}
.social_signup{
	padding-top:20px;
	width:300px;
	display:flex;
	flex-direction:column;
	align-items:center;
	padding-bottom:10px;
	grid-gap:10px;
	gap:10px;
}
.scbx_ct{
	display:flex;
	width:100%;
	justify-content:space-around;
}
.scbx_txt{
	font-family:'inter';
	color: rgba(120, 120, 120, 1);
	font-size:12px;
	letter-spacing:1px;
}
.sc_bx:hover{
	scale:1.01;
	transition:0.1s;
}
.sc_bx{
	width:180px;
	border-radius:5px;
	border:1px solid rgba(218, 218, 218, 0.5);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	height:38px;
}
.lgin_ct1{
	width:100%;
	height:100vh;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.lgin_form1{
	margin-top:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	grid-gap:20px;
	gap:20px;
	flex-direction:column;
	width:300px;
	height:550px;
}
.bth{
	width:230px;
}
.lgin_txt1{
	padding:10px 5px 10px 5px;
	font-family:'inter';
	border-radius:5px;
	font-size:16px;
	border:none;
	background:rgba(218, 218, 218, 0.8);
}
.lgin_btn1{
	width:230px;
	font-family:'inter';
	font-size:16px;
	height:40px;
	border:none;
	border-radius:5px;
	color:#fff;
	background:#333;
}
.lgin_btn1:hover{
	cursor:pointer;
}
.lgin_tct{
	display:flex;
	flex-direction:column;
	grid-gap:2px;
	gap:2px;
}
.lgin_lbl{
	font-size:12px;
}
.alert_txt{
	font-size:12px;
	color:red;
}
.ck_btn{
	width:70px;
	height:25px;
	font-size:10px;
	font-family:'inter';
	background:#333;
	color:#fff;
	border:none;
	border-radius:5px;
}
.user_ct{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	grid-gap:20px;
	gap:20px;
	position:relative;
	height:100%;
}
.nv{
	width:80%;
	padding-top:20px;
}
.nv_lg{
	padding:10px;
	text-decoration:none;
	color:#222;
	font-size:30px;
	font-family:'Black han sans';
}
.usr_ct{
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.usr_bx{
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	flex-direction:column;
	width:600px;
	height:500px;
}
.ui_tct{
	width:600px;
	height:150px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display:flex;
	justify-content:space-around;
	flex-direction:column;
}
.reports{
	width:600px;
	height:150px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display:flex;
	align-items: center;
	flex-direction:column;
}
.ui_ct{
	display:flex;
	flex-direction:row;
	grid-gap:10px;
	gap:10px;
}
.ui_tt{
	font-family:'Black han sans';
	margin-left:30px;
	font-size:18px;
	color:#222;
}
.ui_txt{
	font-family:'inter';
	font-size:14px;
	color:#222;
}
.ui_tp{
	width:100%;
	display:flex;
	justify-content:space-between;
}
.ui_chg{
	margin-right:20px;
	width:60px;
	font-size:14px;
	font-family:'inter';
	font-weight:800;
	border-radius:3px;
	background:#fff;
	height:100%;
	border:1px solid #333;
}
.ui_chg:hover{
	cursor:pointer;
	background:#333;
	color:#fff;
	transition:0.2s;
}
.credit_btn{
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
	width:60px;
	height:24px;
	color:#333;
	font-family:'Black han sans';
	font-size:16px;
	background:none;
	border:1px solid #333;
	border-radius:5px;
}
.credit_btn:hover{
	cursor:pointer;
	background:#333;
	color:#fff;
	transition:0.1s;
}
.credit_btn2{
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
	width:60px;
	height:24px;
	color:#d32c42;
	font-family:'Black han sans';
	font-size:16px;
	background:none;
	border:1px solid #d32c42;
	border-radius:5px;
}
.credit_btn2:hover{
	cursor:pointer;
	background:#d32c42;
	color:#fff;
	transition:0.1s;
}
.ui_chg_fn{
	margin-right:20px;
	width:60px;
	font-size:14px;
	font-family:'inter';
	font-weight:800;
	border-radius:3px;
	height:100%;
	background:none;
	border:1px solid #004AE6;
}
.ui_chg_fn:hover{
	cursor:pointer;
	background:#4C7DE6;
	border:none;
	color:#fff;
	transition:0.2s;
}
.bx_txt{
	width:95%;
	font-family:'Black han sans';
	font-size:22px;
	color:#222;
}
.bx_select{
	width:95%;
	display: flex;
	justify-content: start;
	align-items: center;
	grid-gap:10px;
	gap:10px;
	font-size:14px;
}

.bx_report{
	width:95%;
	height:85%;
	scroll:auto;
	overflow-x:hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.bx_prb{
	width:95%;
	height:85%;
	scroll:auto;
	overflow-x:hidden;
}
.btn_cts{
	display:flex;
	grid-gap:10px;
	gap:10px;
	padding-bottom:200px;
}
.prb_reset{
	width:200px;
	height:50px;
	display:flex;
	align-items:center;
	font-family:'inter';
	font-weight:800;
	color:#222;
	font-size:16px;
	justify-content:center;
	border-radius:10px;
	border:1px solid rgba(255,102,102,1);
}
.prb_reset:hover{
	background:rgba(255,102,102,0.5);
	border:1px solid rgba(255,102,102,0.5);
	transition:0.2s;
}
.user_out{
	width:200px;
	height:50px;
	display:flex;
	align-items:center;
	font-family:'inter';
	font-weight:800;
	color:#fff;
	font-size:16px;
	justify-content:center;
	border-radius:10px;
	background:rgba(255,102,102,1);
}
.user_out:hover{
	color:#222;
	transition:0.2s;
}
.test{
	margin-top:5px;
	margin-left:5px;
	width:95%;
	height:50px;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
	border-radius:10px;
	display:flex;
	align-items:center;
	justify-content:space-around;
}
.test:hover{
	scale:1.01;
	transition:0.5s;
}
.cor_prb{
	width:100px;
	height:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:18px;
	font-weight:800;
	grid-gap:2px;
	gap:2px;
}
.down_prb{
	width:60px;
	height:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:18px;
	font-weight:800;
	grid-gap:2px;
	gap:2px;
}
.del_prb{
	text-decoration:none;
	color:#000;
	width:60px;
	height:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:18px;
	font-weight:800;
	grid-gap:2px;
	gap:2px;
}
.cor_prb:hover{
	cursor:pointer;
	scale:1.05;
	transition:0.1s;
}
.down_prb:hover{
	cursor:pointer;
	scale:1.05;
	transition:0.1s;
}
.del_prb:hover{
	scale:1.05;
	transition:0.1s;
}
.prb_btn_bx{
	width:200px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.prb_types{
	font-size:16px;
	width:100px;
	font-family:'inter';
}
.solved_time{
	font-size:12px;
	padding-top:8px;
	font-family:'inter';
	color:#666;
}
.crt_gage{
	width:200px;
	height:10px;
	display: flex;
}
.category_crt_bx{
	width:100%;
	height:300px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.category_chart_bx{
	width:300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.cg_chart_bx_tt{
	font-family: "Black han sans";
	font-size:16px;
	text-align: center;
}
.crt_sm_title{
	font-family: 'Black han sans';
	font-size:16px;
}
.report_ct{
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.crt_bx{
	margin-top:10px;
	height:50px;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap:10px;
	gap:10px;
}
.bx_prb::-webkit-scrollbar {
	width: 12px; /* 세로 스크롤바 너비 */
}
.bx_prb::-webkit-scrollbar-thumb {
	background-color: #888; /* 스크롤바 색상 */
    border-radius: 6px; /* 스크롤바 모서리의 반경 */
}
.bx_prb::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* 스크롤바 트랙의 배경색 */
    border-radius: 6px; /* 스크롤바 트랙의 모서리 반경 */
}
.bx_report::-webkit-scrollbar {
	width: 12px; /* 세로 스크롤바 너비 */
}
.bx_report::-webkit-scrollbar-thumb {
	background-color: #888; /* 스크롤바 색상 */
    border-radius: 6px; /* 스크롤바 모서리의 반경 */
}
.bx_report::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* 스크롤바 트랙의 배경색 */
    border-radius: 6px; /* 스크롤바 트랙의 모서리 반경 */
}
@media(max-width:800px){
	.user_ct{
		grid-gap:10px;
		gap:10px;
	}
	.ui_txt{
		font-size:12px;
	}
	.ui_tct{
		width:95%;
	}
	.reports{
		width:95%;
	}
	.prb_btn_bx{
		width:180px;
	}
	.prb_types{
		font-size:12px;
		text-align: center;
		width:75px;
	}
	.solves{
		font-size:12px;
	}
	.solved_time{
		font-size:10px;
		width:72px;
	}
	.cor_prb{
		font-size: 12px;
	}
	.down_prb{
		font-size:12px;
	}
	.del_prb{
		font-size:12px;
	}
	.usr_bx{
		width:95%;
	}
	.btn_cts{
		flex-direction:column;
	}
	.prb_reset{
		width:150px;
	}
	.user_out{
		width:150px;
	}
}
.pop_ctx{
	position:fixed;
	top:0;
	left:0;
	width:100vw;
	height:100%;
	background:rgba(255, 255, 255, 0.5);
	display: flex;
	align-items:start;
	justify-content: center;
	z-index: 99999;
}
.pop_bxt{
	margin-top:50px;
	width:700px;
  height:600px;
	background:#fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display: flex;
  align-items: center;
  z-index: 999999;
  overflow-y: scroll;
  flex-direction: column;
}
.bxt_bxt1{
	width:80%;
	height:200px;
  display: flex;
  flex-direction: row;
}
.bxt1_l{
  flex-grow:1;
}
.bxt1_r{
  flex-grow:1;
  display: flex;
  justify-content: space-around;
}
.bxt_time{
  font-size:12px;
  font-family: 'inter';
  color:#999;
}
.score_bxt{
  height:200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.score_cnt{
  font-family: 'Black han sans';
  color:rgba(255,0,0,0.8);
  font-size:60px;
}
.pop_bxt_title{
	font-family: 'Black han sans';
	font-size:30px;
  margin-top: 20px;
}
.icon_ctt{
  margin-top: 10px;
  display: flex;
  grid-gap:20px;
  gap:20px;
}
.icon_bxt{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.icon_bxt:hover{
  cursor:pointer;
}
.icon_bxt_tt{
  font-size:12px;
}
.bxt_bxt2{
  width:80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bxt2_tt{
  width:100%;
  font-family: 'Black han sans';
}
.bxt_bxt3{
  margin-top:10px;
  width:80%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.radar_table{
  width:90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.radar_t{
  border:1px solid #ccc;
  height:70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap:5px;
  gap:5px;
  text-align:'center';
  width:100px;
}
.bxt_bxt4{
  margin-top: 50px;
  margin-bottom: 200px;
  width:80%;
  display: flex;
  flex-direction: column;
}
.wrong_lst{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrlst_bx{
  border:1px solid #ccc;
}

@media(max-width:800px){
  .pop_bxt{
    width:90%;
    height:90vh;
  }
  .pop_bxt_title{
    text-align: center;
  }
  .bxt_bxt1{
    flex-direction: column;
    height:auto;
  }
  .wrlst_bx td{
    padding:10px;
  }
  .wrlst_bx{
    font-size:12px;
  }
}
.brd{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
}
.brd_ct{
	width:100%;
	max-width:1000px;
	flex-direction:column;
}
.brd_tt{
	font-size:24px;
	margin-top:80px;
	font-family:'black han sans';
}
.dss{
	width:100%;
	max-width:1000px;
	height:100px;
	overflow:hidden;
	position: relative;
	align-items:center;
}
.brd_lst:hover{
	cursor:pointer;
}
.wrt{
	width:100%;
}
.wrt_btn{
	float:right;
	padding:5px 10px 5px 10px;
	font-weight:800;
	color:#fff;
	background:#333;
	border:none;
	border-radius:2px;
}
.pagination{
	display:flex;
	justify-content:center;
	align-items:center;
}
ul{
    list-style: none;
	padding: 0;
}
ul.pagination li {
	display: inline-block;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

ul.pagination li a {
    text-decoration: none;
    color: #444;
    font-size: 1rem;
}

ul.pagination li.active a {
    color: white;
}

ul.pagination li.active {
    background-color: #444;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color:#000;
}

.page-selection {
	width: 48px;
    height: 30px;
	color: #444;
}
table,th,td{
	border-collapse:collapse;
}
td{
	padding:20px;
	text-align:center;
}
thead th{
	border-bottom:1px solid black;

}
thead th:nth-child(1) {
  width: 10%;
}
thead th:nth-child(2) {
  width: 50%;
}
thead th:nth-child(3) {
  width: 20%;
}
thead th:nth-child(4) {
  width: 20%;
}
tbody tr:hover{
	background:rgba(240,240,240);
	transition:0.2s;
}
.brdtb_ct{
	margin-top:20px;
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.brd_tb{
	width:800px;
}

@media(max-width:1000px){
	thead th:nth-child(2) {
		width: 40%;
	}
	thead th:nth-child(4) {
		width: 30%;
	}
	.brd_list td{
		font-size: 12px;
		padding:10px;
	}
	.brd_ct{
		max-width:100%;
	}
	.brd_tb{
		width:100%;
	}
	.logo2{
		font-size:30px;
		margin-left:5px;
	}
	.brd_tt{
		font-size:20px;
		margin-left:5px;
	}
	th{
		font-size:12px;
	}
	thead th:nth-child(1) {
		width: 10%;
	}
	thead th:nth-child(2) {
		width: 50%;
	}
	thead th:nth-child(3) {
		width: 20%;
	}
	thead th:nth-child(3) {
		width: 20%;
	}
}
.pst{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
}
.pst_ct{
	width:95%;
	max-width:1000px;
	margin-top:80px;
}
.pst_tt{
	margin-top:20px;
	font-size:36px;
	font-family:'black han sans';
}
.pst_detail{
	display:flex;
	grid-gap:10px;
	gap:10px;
	font-family:'inter';
	align-items:center;
}
.pst_usr{
	font-size:16px;
	font-weight:800;
}
.pst_upt{
	font-size:12px;
}
.bar{
	color:rgb(200,200,200);
}
.dvd_bar{
	width:100%;
	border:1px solid rgb(200,200,200);
	margin-top:10px;
	margin-bottom:10px;
}
.cmt{
	width:100%;
	padding:10px;
	border-radius: 10px;
	background:rgba(240,240,240);
	display:flex;
	margin-bottom:10px;
	position:relative;
}
.cmt_user_name{
	width:100px;
}
.cmt_content{
	width:700px;
}
.cmt_updated_at{
	width:180px;
	text-align:right;
}
.cmtform{
	width:100%;
	resize:none;
	font-size:16px;
	height:60px;
}
.cmtsbm{
	padding:5px 10px 5px 10px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:2px;
}
.usbtn{
	margin-right:5px;
}
.cmtsbm:hover{
	cursor:pointer;
}
.cmtdel{
	font-size:12px;
	position:absolute;
	top:5px;
	right:5px;
}
.cmtdel:hover{
	cursor:pointer;
}
@media(max-width:1000px){
	.pst_tt{
		font-size:20px;
	}
	.cmt{
		padding:0;
		font-size:12px;
	}
	.cmt_user_name{
		flex-grow:1;
		display:flex;
		align-items:center;
		margin-left:5px;
	}
	.cmt_content{
		flex-grow:7;
		display:flex;
		align-items:center;
	}
	.cmt_updated_at{
		flex-grow:2;
		display:flex;
		align-items:center;
		margin-right:5px;
	}
}
.txt_inputs{
	width:1000px;
	height:400px;
	margin-bottom:50px;
}
.txt_tt{
	margin-bottom:5px;
	font-size:16px;
	font-family:'inter';
}
.brd_ct{
	margin-top:80px;
	width:95%;
}
.edits_tt{
	width:100%;
}
.txt_btns{
	width:80px;
	height:40px;
	margin-left:auto;
	margin-right:0;
	display:flex;
	align-items:center;
	justify-content:center;
	border:none;
	border-radius:5px;
	color:#fff;
	background:#333;
	font-size:16px;
}
.txt_btns:hover{
	cursor:pointer;
}
.tt_cts{
	font-size:24px;
	font-family:'inter';
	font-weight:800;
	margin-bottom:5px;
	display:flex;
	align-items:center;
	grid-gap:10px;
	gap:10px;
}
.tt_input{
	width:800px;
	font-size:20px;
}

@media(max-width:1000px){
	.tt_input{
		width:80%;
		font-size:16px;
	}
	.tt_cts{
		font-size:16px;
	}
	.txt_inputs{
		width:100%;
		height:300px;
		margin-bottom:100px;
	}
}
.price_ct{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	grid-gap:50px;
	gap:50px;
}
.collect_sub{
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.5);
	position: fixed;
	top:0;
	left:0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.collect_inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width:500px;
	height:300px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	background: #fff;
	border-radius: 10px;
}
.collect_bx{
	width:95%;
	height:200px;
	display: flex;
	flex-wrap: wrap;
	grid-gap:10px;
	gap:10px;
	align-items: center;
	justify-content: center;
}
.collect_chs{
	width:100px;
	height:100px;
	border-radius: 10px;
	border:2px solid #ccc;
	color:#ccc;
	font-family: 'Black han sans';
	font-size: '20px';
	display: flex;
	align-items: center;
	justify-content: center;
}
.collect_res{
	width:95%;
	display: flex;
	justify-content: space-around;
}
.collect_price{
	font-family: 'Black han sans';
	color:#000;
	font-size:24px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.collect_btn{
	width:120px;
	height:60px;
	font-size: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	background:#333;
	color:#fff;
	font-family: 'Black han sans';
}
.prc_ct{
	margin-top: 100px;
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	grid-gap:10px;
	gap:10px;
}
.prc_bx{
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	flex-direction:row;
	width:600px;
	height:100px;
}
.prc_bx:hover{
	cursor:pointer;
}
.pc_txt{
	width:30%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-family:'Black han sans';
	font-size:22px;
	color:#222;
}
.cr_bt{
	scale:1.5;
}
.cr_bt:hover{
	cursor:pointer;
	scale:1.7;
	transition:0.1s;
}
.cr_column{
	display:flex;
	justify-content:center;
	flex-direction:column;
	grid-gap:10px;
	gap:10px;
}
.cr_row{
	display:flex;
	justify-content:center;
	flex-direction:row;
	grid-gap:10px;
	gap:10px;
}
.pr_sale{
	color:rgba(255,0,0,0.8);
	font-size:16px;
}
.sale_per{
	color:rgba(51,51,51,0.7);
	font-size:14px;
}
.pc_btn{
	width:10%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-weight:900;
	font-family:'Black han sans';
	font-size:30px;
	text-decoration: none;
	color:#222;
}
.refund_intro{
	width:600px;
}
.refund_txt{
	width:100%;
	text-align:right;
	font-family:'Black han sans';
}
.refund_ser{
	width:100%;
	font-family:'inter';
	font-size:12px;
	color:rgba(105,105,105);
}
@media(max-width:600px){
	.price_tct{
		width:95%;
	}
	.collect_inner{
		width:90%;
		height:50%;
	}
	.collect_bx{
		height:auto;
	}
	.refund_intro{
		width:95%;
	}
	.refund_ser{
		width:95%;
	}
	.prc_bx{
		width:95%;
	}
	.pc_txt{
		font-size:16px;
	}
}
.pop_ct{
	width:100%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	background:rgba(255,255,255,0.5);
	top:0;
	left:0;
}
.pop_row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size:16px;
  color:#333;
}
.chosen_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width:90px;
  height:40px;
  border:2px solid #333;
  border-radius: 10px;
  color:#333;
  font-family: 'Black han sans';
}
.pay_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width:90px;
  height:40px;
  border:2px solid #777;
  border-radius: 10px;
  color:#777;
  font-family: 'Black han sans';
}
.pay_bx{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap:5px;
  gap:5px;
  justify-content: center;
  align-items: center;
}
.pop_bx{
	position:relative;
	width:400px;
	height:300px;
	background:#fff;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	border-radius:10px;
	display:flex;
	align-items:center;
	justify-content:space-around;
	flex-direction:column;
	grid-gap:10px;
	gap:10px;
  z-index: 999999999;
}
.pop_ctt{
	width:80%;
}
.pop_close{
	position:absolute;
	top:5px;
	right:5px;
}
.pop_close:hover{
	cursor:pointer;
}
.pop_title{
	font-family:'Black han sans';
	font-size:30px;
}
.card_submit{
	width:80%;
	height:50px;
	border:none;
	background:#333;
	border-radius:10px;
	color:#fff;
	font-size:20px;
	font-family:'Black han sans';
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

.crt_ct{
  width:100%;
  height:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.crt_fm{
  margin-top:100px;
  margin-bottom:100px;
  display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	flex-direction:column;
	width:1200px;
	height:600px;
}
.crt_intro{
  width:100%;
  height:10%;
  display: flex;
  align-items: center;
  margin-left:5%;
}
.crt_title{
  font-family: 'Black han sans';
  font-size:24px;
}
.crt_chk{
  width:90%;
  height:80%;
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-auto-rows: auto; /* 행 높이는 내용에 맞게 자동 설정 */
  grid-auto-flow: column; /* 요소들이 세로 방향으로 채워지도록 설정 */
  grid-gap: 10px;
  gap: 10px; /* 요소 간의 간격 */
  overflow: auto; /* 요소가 넘칠 경우 스크롤 생성 */
}
.crt_btn{
  width:100%;
  height:10%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.crt_btn_clk{
  display: flex;
  align-items: center;
  justify-content: center;
  width:80px;
  height:40px;
  margin-right: 20px;
  background:#333;
  color:#fff;
  font-family: 'Black han sans';
  border-radius: 10px;
}
.chk_nmb{
  display: flex;
  flex-direction: row;
  height:40px;
  grid-gap:10px;
  gap:10px;
}
.nmb_title{
  font-size:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Black han sans';
}
.nmb_btn{
  display: flex;
  grid-gap:5px;
  gap:5px;
  align-items: center;
}
.nmb_bx{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:5px;
  width:120px;
  height:20px;
  font-size:16px;
  font-family: 'Black han sans';
  border:1px solid #ddd;
  border-radius: 5px;
}
.nmb_bx:focus{
  outline:1px solid #333;
}
.btn_des{
  width:20px;
  height:20px;
  font-size:16px;
  border-radius:3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Black han sans';
  background:#ddd;
  color:#fff;
}
.btn_des:hover{
  cursor:pointer;
}
@media(max-width:1200px){
  .crt_fm{
    width:800px;
  }
  .crt_chk{
    grid-template-rows: repeat(15, 1fr);
  }
}
@media(max-width:800px){
  .crt_fm{
    width:90%;
  }
  .crt_chk{
    grid-template-rows: repeat(30, 1fr);
  }
}
@media(max-width:500px){
  .crt_chk{
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
  .crt_fm{
    height:500px;
  }
}
.make_tct{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height:100vh;
}

.make_ct{
  width:800px;
	height:400px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display:flex;
	justify-content:space-around;
	flex-direction:column;
  align-items: center;
}
.make_lst{
  width:90%;
  display: flex;
}
.make_btn{
  width:80px;
  height:40px;
  border-radius: 10px;
  background: #333;
  color:#fff;
  font-family: 'Black han sans';
  display: flex;
  align-items: center;
  justify-content: center;
}
.make_btn:hover{
  cursor:pointer;
}
.make_title{
  font-family: 'Black han sans';
  font-size:18px;
  display: flex;
  align-items: center;
  width:100px;
}
.make_year{
  margin-left:30px;
  display: flex;
  grid-gap:20px;
  gap:20px;
  align-items: center;
}
.make_counts{
  margin-left:30px;
  display: flex;
  grid-gap:10px;
  gap:10px;
  align-items: center;
}
.make_fr{
  margin-left: 30px;
}
.make_category{
  margin-left: 30px;
  display: flex;
  grid-gap:30px;
  gap:30px;
}
.items_ct{
  display: flex;
  width:300px;
  flex-wrap: wrap;
  grid-gap:10px;
  gap:10px;
}
.make_diff{
  display: flex;
}
.make_items{
  font-size:12px;
  padding:3px;
  height:18px;
  display: flex;
  grid-gap:10px;
  gap:10px;
  justify-content: space-around;
  border:1px solid black;
  border-radius: 10px;
}
.range-slider__range{
  margin-left: 30px;
  -webkit-appearance: none;
  width: 200px;
  height: 6px; /* 슬라이더 바 두께 */
  background: #ddd; /* 채워지는 색 제거하고 기본 바 색 지정 */
  border-radius: 5px;
  outline: none; /* 포커스 시 외곽선 제거 */
  cursor: pointer;
}
.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none; /* 기본 브라우저 스타일 제거 */
  width: 20px;
  height: 20px;
  background: #333; /* 슬라이더 점 색상 */
  border-radius: 50%; /* 원형 모양 */
  cursor: pointer;
  border: 2px solid #fff; /* 점 외곽 경계선 */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* 점 그림자 */
}
.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.range-slider__range::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 40px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #333;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid #333;
    border-bottom: 7px solid transparent;
    content: '';
  }
}
.range_desc{
  width:200px;
  margin-left:20px;
  font-size:12px;
}

@media(max-width:800px){
  .make_ct{
    width:95%;
    height: auto;
    min-height: 400px;
  }
  .make_category{
    flex-direction: column;
  }
  .items_ct{
    width:80%;
  }
  .range-slider__range{
    width:50%;
  }
  .make_diff{
    flex-direction: column;
    align-items: center;
  }
  .make_title{
    width:70px;
  }
}
