.column2{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
}
.row2{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:row;
}
.prb{
	gap:50px;
	width:100%;
	height:100vh;
	position:relative;
}
.header2{
	width:100%;
	max-width:800px;
	justify-content:start;
	margin-top:50px;
}
.logo2{
	padding:50px 0 30px 0;
	font-family:'Black han sans';
	font-size:40px;
	letter-spacing:-0.07em;
	text-align:center;
	line-height:0.2em;
	color:#333333;
	font-weight:200;
	text-decoration:None;
}
.selector{
	margin-top:100px;
	width:100%;
	max-width:800px;
	margin-bottom:100px;
}
.pb_ct{
	width:100%;
	justify-content:space-between;
}
.pb_bx{
	width:25%;
	height:80px;
	font-family:'black han sans';
	color:#222;
	font-size:30px;
	text-decoration:None;
}
.pb_st{
	justify-content:space-around;
	width:100%;
	max-width:800px;
	height:400px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
}
.st_title{
	font-family:'inter';
	font-size:20px;
	color:#333;
	font-weight:700;
	text-align:center;
	width:85%;
}
.st_ct{
	display:flex;
	align-items:center;
	flex-direction:row;
	width:90%;
	justify-content:start;
	gap:30px;
}
.st_pt{
	display:flex;
	align-items:center;
	flex-direction:row;
	width:90%;
	justify-content:start;
	gap:30px;
}
.st_txt{
	font-family:'inter';
	font-size:20px;
	color:#333;
	font-weight:700;
}
.btn_ct{
	justify-content:end;
}
.dup_chx{
	width:15px;
	height:15px;
}
.st_btn{
	font-family:'black han sans';
	font-size:18px;
	border-radius:5px;
	font-weight:200;
	padding:10px;
	width:80px;
	color:#fff;
	border:None;
}
.st_btn:hover{
	cursor:pointer;
}
.st_btn_dt{
	font-size:12px;
}
.pop{
	font-size:12px;
	color:red;
}
.chbx_ct{
	gap:10px;
}
.chbx{
	width:15px;
	height:15px;
}
.chbx_txts{
	font-family:'inter';
	font-size:20px;
}
.gaps{
	width:100%;
	height:0;
}
.footer2{
	width:100%;
	height:100px;
	background:#383843;
}
.footer_bx2{
	gap:5px;
	width:100%;
	max-width:800px;
	justify-content:start;
}
.footer_txt2{
	font-family:'Manrope';
	font-size:12px;
	color:rgba(255,255,255,0.5);
	line-height:1.5em;
}
.caution{
	color:red;
}
@media(max-width:395px){
	.pb_bx{
		font-size:26px;
	}
}
@media(max-width:810px){
	.prb{
		gap:30px;
		height:105vh;
	}
	.btn_ct{
		margin-top:40px;
	}
	.header2{
		width:90%;
	}
	.chbx_ct{
		gap:0;
	}
	.logo2{
		padding-top:30px;
	}
	.st_title{
		font-size:16px;
		margin-bottom:20px;
	}
	.st_txt{
		font-size:16px;
	}
	.gaps{
		height:150px;
	}
	.footer_bx2{
		width:90%;
	}
	.chbx_txts{
		font-size:12px;
	}
	.st_ct{
		gap:10px;
	}
	.st_pt{
		gap:30px;
	}
}
