input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

.crt_ct{
  width:100%;
  height:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.crt_fm{
  margin-top:100px;
  margin-bottom:100px;
  display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	flex-direction:column;
	width:1200px;
	height:600px;
}
.crt_intro{
  width:100%;
  height:10%;
  display: flex;
  align-items: center;
  margin-left:5%;
}
.crt_title{
  font-family: 'Black han sans';
  font-size:24px;
}
.crt_chk{
  width:90%;
  height:80%;
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-auto-rows: auto; /* 행 높이는 내용에 맞게 자동 설정 */
  grid-auto-flow: column; /* 요소들이 세로 방향으로 채워지도록 설정 */
  gap: 10px; /* 요소 간의 간격 */
  overflow: auto; /* 요소가 넘칠 경우 스크롤 생성 */
}
.crt_btn{
  width:100%;
  height:10%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.crt_btn_clk{
  display: flex;
  align-items: center;
  justify-content: center;
  width:80px;
  height:40px;
  margin-right: 20px;
  background:#333;
  color:#fff;
  font-family: 'Black han sans';
  border-radius: 10px;
}
.chk_nmb{
  display: flex;
  flex-direction: row;
  height:40px;
  gap:10px;
}
.nmb_title{
  font-size:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Black han sans';
}
.nmb_btn{
  display: flex;
  gap:5px;
  align-items: center;
}
.nmb_bx{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:5px;
  width:120px;
  height:20px;
  font-size:16px;
  font-family: 'Black han sans';
  border:1px solid #ddd;
  border-radius: 5px;
}
.nmb_bx:focus{
  outline:1px solid #333;
}
.btn_des{
  width:20px;
  height:20px;
  font-size:16px;
  border-radius:3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Black han sans';
  background:#ddd;
  color:#fff;
}
.btn_des:hover{
  cursor:pointer;
}
@media(max-width:1200px){
  .crt_fm{
    width:800px;
  }
  .crt_chk{
    grid-template-rows: repeat(15, 1fr);
  }
}
@media(max-width:800px){
  .crt_fm{
    width:90%;
  }
  .crt_chk{
    grid-template-rows: repeat(30, 1fr);
  }
}
@media(max-width:500px){
  .crt_chk{
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
  .crt_fm{
    height:500px;
  }
}