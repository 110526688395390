.social_signup{
	padding-top:20px;
	width:300px;
	display:flex;
	flex-direction:column;
	align-items:center;
	padding-bottom:10px;
	gap:10px;
}
.scbx_ct{
	display:flex;
	width:100%;
	justify-content:space-around;
}
.scbx_txt{
	font-family:'inter';
	color: rgba(120, 120, 120, 1);
	font-size:12px;
	letter-spacing:1px;
}
.sc_bx:hover{
	scale:1.01;
	transition:0.1s;
}
.sc_bx{
	width:180px;
	border-radius:5px;
	border:1px solid rgba(218, 218, 218, 0.5);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	height:38px;
}
.lgin_ct1{
	width:100%;
	height:100vh;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.lgin_form1{
	margin-top:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	gap:20px;
	flex-direction:column;
	width:300px;
	height:550px;
}
.bth{
	width:230px;
}
.lgin_txt1{
	padding:10px 5px 10px 5px;
	font-family:'inter';
	border-radius:5px;
	font-size:16px;
	border:none;
	background:rgba(218, 218, 218, 0.8);
}
.lgin_btn1{
	width:230px;
	font-family:'inter';
	font-size:16px;
	height:40px;
	border:none;
	border-radius:5px;
	color:#fff;
	background:#333;
}
.lgin_btn1:hover{
	cursor:pointer;
}
.lgin_tct{
	display:flex;
	flex-direction:column;
	gap:2px;
}
.lgin_lbl{
	font-size:12px;
}
.alert_txt{
	font-size:12px;
	color:red;
}
.ck_btn{
	width:70px;
	height:25px;
	font-size:10px;
	font-family:'inter';
	background:#333;
	color:#fff;
	border:none;
	border-radius:5px;
}