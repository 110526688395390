.pop_ct{
	width:100%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	background:rgba(255,255,255,0.5);
	top:0;
	left:0;
}
.pop_row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size:16px;
  color:#333;
}
.chosen_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width:90px;
  height:40px;
  border:2px solid #333;
  border-radius: 10px;
  color:#333;
  font-family: 'Black han sans';
}
.pay_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width:90px;
  height:40px;
  border:2px solid #777;
  border-radius: 10px;
  color:#777;
  font-family: 'Black han sans';
}
.pay_bx{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:5px;
  justify-content: center;
  align-items: center;
}
.pop_bx{
	position:relative;
	width:400px;
	height:300px;
	background:#fff;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	border-radius:10px;
	display:flex;
	align-items:center;
	justify-content:space-around;
	flex-direction:column;
	gap:10px;
  z-index: 999999999;
}
.pop_ctt{
	width:80%;
}
.pop_close{
	position:absolute;
	top:5px;
	right:5px;
}
.pop_close:hover{
	cursor:pointer;
}
.pop_title{
	font-family:'Black han sans';
	font-size:30px;
}
.card_submit{
	width:80%;
	height:50px;
	border:none;
	background:#333;
	border-radius:10px;
	color:#fff;
	font-size:20px;
	font-family:'Black han sans';
}