.user_ct{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	gap:20px;
	position:relative;
	height:100%;
}
.nv{
	width:80%;
	padding-top:20px;
}
.nv_lg{
	padding:10px;
	text-decoration:none;
	color:#222;
	font-size:30px;
	font-family:'Black han sans';
}
.usr_ct{
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.usr_bx{
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	flex-direction:column;
	width:600px;
	height:500px;
}
.ui_tct{
	width:600px;
	height:150px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display:flex;
	justify-content:space-around;
	flex-direction:column;
}
.reports{
	width:600px;
	height:150px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display:flex;
	align-items: center;
	flex-direction:column;
}
.ui_ct{
	display:flex;
	flex-direction:row;
	gap:10px;
}
.ui_tt{
	font-family:'Black han sans';
	margin-left:30px;
	font-size:18px;
	color:#222;
}
.ui_txt{
	font-family:'inter';
	font-size:14px;
	color:#222;
}
.ui_tp{
	width:100%;
	display:flex;
	justify-content:space-between;
}
.ui_chg{
	margin-right:20px;
	width:60px;
	font-size:14px;
	font-family:'inter';
	font-weight:800;
	border-radius:3px;
	background:#fff;
	height:100%;
	border:1px solid #333;
}
.ui_chg:hover{
	cursor:pointer;
	background:#333;
	color:#fff;
	transition:0.2s;
}
.credit_btn{
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
	width:60px;
	height:24px;
	color:#333;
	font-family:'Black han sans';
	font-size:16px;
	background:none;
	border:1px solid #333;
	border-radius:5px;
}
.credit_btn:hover{
	cursor:pointer;
	background:#333;
	color:#fff;
	transition:0.1s;
}
.credit_btn2{
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
	width:60px;
	height:24px;
	color:#d32c42;
	font-family:'Black han sans';
	font-size:16px;
	background:none;
	border:1px solid #d32c42;
	border-radius:5px;
}
.credit_btn2:hover{
	cursor:pointer;
	background:#d32c42;
	color:#fff;
	transition:0.1s;
}
.ui_chg_fn{
	margin-right:20px;
	width:60px;
	font-size:14px;
	font-family:'inter';
	font-weight:800;
	border-radius:3px;
	height:100%;
	background:none;
	border:1px solid #004AE6;
}
.ui_chg_fn:hover{
	cursor:pointer;
	background:#4C7DE6;
	border:none;
	color:#fff;
	transition:0.2s;
}
.bx_txt{
	width:95%;
	font-family:'Black han sans';
	font-size:22px;
	color:#222;
}
.bx_select{
	width:95%;
	display: flex;
	justify-content: start;
	align-items: center;
	gap:10px;
	font-size:14px;
}

.bx_report{
	width:95%;
	height:85%;
	scroll:auto;
	overflow-x:hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.bx_prb{
	width:95%;
	height:85%;
	scroll:auto;
	overflow-x:hidden;
}
.btn_cts{
	display:flex;
	gap:10px;
	padding-bottom:200px;
}
.prb_reset{
	width:200px;
	height:50px;
	display:flex;
	align-items:center;
	font-family:'inter';
	font-weight:800;
	color:#222;
	font-size:16px;
	justify-content:center;
	border-radius:10px;
	border:1px solid rgba(255,102,102,1);
}
.prb_reset:hover{
	background:rgba(255,102,102,0.5);
	border:1px solid rgba(255,102,102,0.5);
	transition:0.2s;
}
.user_out{
	width:200px;
	height:50px;
	display:flex;
	align-items:center;
	font-family:'inter';
	font-weight:800;
	color:#fff;
	font-size:16px;
	justify-content:center;
	border-radius:10px;
	background:rgba(255,102,102,1);
}
.user_out:hover{
	color:#222;
	transition:0.2s;
}
.test{
	margin-top:5px;
	margin-left:5px;
	width:95%;
	height:50px;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
	border-radius:10px;
	display:flex;
	align-items:center;
	justify-content:space-around;
}
.test:hover{
	scale:1.01;
	transition:0.5s;
}
.cor_prb{
	width:100px;
	height:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:18px;
	font-weight:800;
	gap:2px;
}
.down_prb{
	width:60px;
	height:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:18px;
	font-weight:800;
	gap:2px;
}
.del_prb{
	text-decoration:none;
	color:#000;
	width:60px;
	height:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:18px;
	font-weight:800;
	gap:2px;
}
.cor_prb:hover{
	cursor:pointer;
	scale:1.05;
	transition:0.1s;
}
.down_prb:hover{
	cursor:pointer;
	scale:1.05;
	transition:0.1s;
}
.del_prb:hover{
	scale:1.05;
	transition:0.1s;
}
.prb_btn_bx{
	width:200px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.prb_types{
	font-size:16px;
	width:100px;
	font-family:'inter';
}
.solved_time{
	font-size:12px;
	padding-top:8px;
	font-family:'inter';
	color:#666;
}
.crt_gage{
	width:200px;
	height:10px;
	display: flex;
}
.category_crt_bx{
	width:100%;
	height:300px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.category_chart_bx{
	width:300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.cg_chart_bx_tt{
	font-family: "Black han sans";
	font-size:16px;
	text-align: center;
}
.crt_sm_title{
	font-family: 'Black han sans';
	font-size:16px;
}
.report_ct{
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.crt_bx{
	margin-top:10px;
	height:50px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap:10px;
}
.bx_prb::-webkit-scrollbar {
	width: 12px; /* 세로 스크롤바 너비 */
}
.bx_prb::-webkit-scrollbar-thumb {
	background-color: #888; /* 스크롤바 색상 */
    border-radius: 6px; /* 스크롤바 모서리의 반경 */
}
.bx_prb::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* 스크롤바 트랙의 배경색 */
    border-radius: 6px; /* 스크롤바 트랙의 모서리 반경 */
}
.bx_report::-webkit-scrollbar {
	width: 12px; /* 세로 스크롤바 너비 */
}
.bx_report::-webkit-scrollbar-thumb {
	background-color: #888; /* 스크롤바 색상 */
    border-radius: 6px; /* 스크롤바 모서리의 반경 */
}
.bx_report::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* 스크롤바 트랙의 배경색 */
    border-radius: 6px; /* 스크롤바 트랙의 모서리 반경 */
}
@media(max-width:800px){
	.user_ct{
		gap:10px;
	}
	.ui_txt{
		font-size:12px;
	}
	.ui_tct{
		width:95%;
	}
	.reports{
		width:95%;
	}
	.prb_btn_bx{
		width:180px;
	}
	.prb_types{
		font-size:12px;
		text-align: center;
		width:75px;
	}
	.solves{
		font-size:12px;
	}
	.solved_time{
		font-size:10px;
		width:72px;
	}
	.cor_prb{
		font-size: 12px;
	}
	.down_prb{
		font-size:12px;
	}
	.del_prb{
		font-size:12px;
	}
	.usr_bx{
		width:95%;
	}
	.btn_cts{
		flex-direction:column;
	}
	.prb_reset{
		width:150px;
	}
	.user_out{
		width:150px;
	}
}