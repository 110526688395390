@font-face {
  font-family: 'black han sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../BlackHanSans.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../Inter.ttf');
}
@font-face {
  font-family: 'manrope';
  font-style: normal;
  font-weight: 400;
  src: url('../../Manrope.ttf');
}
@font-face {
  font-family: 'NanumMyeongjo';
  font-style: normal;
  font-weight: 400;
  src: url('../../NanumMyeongjo-Regular.ttf');
}
@font-face {
  font-family: 'staatliches';
  font-style: normal;
  font-weight: 400;
  src: url('../../Staatliches.ttf');
}