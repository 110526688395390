.footer{
	width:100%;
	height:100px;
	background:#383843;
	position:absolute;
	bottom:0;
}
.footer_bx{
	width:100%;
	max-width:1000px;
	justify-content:start;
}
.footer_txt{
	font-family:'Manrope';
	font-size:12px;
	color:rgba(255,255,255,0.5);
	line-height:1.5em;
}
@media(max-width:1200px){
	.footer_bx{
		max-width:810px;
	}
}
@media(max-width:810px){
	.footer_bx{
		width:90%;
	}
	.footer{
		height:120px;
	}
}