.tttt{
	position:relative;
}
.prb_bt{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top:100px;
	margin-bottom:100px;
}
.prb_bt_txt{
	font-size:40px;
	font-family: 'Black han sans';
}
.prb_go{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width:100%;
	gap:10px;
}
.prb_item{
	width:160px;
	height:160px;
	background:none;
	border-radius: 10px;
	border:2px solid #333;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color:#333;
	font-family: 'Black han sans';
	font-size:30px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.prb_item_txt{
	margin-top:10px;
	width:160px;
	font-family: 'Black han sans';

}
.hero {
	display:flex;
	padding-top: 100px;
	align-items:center;
	justify-content:center;
	flex-direction: column;
	width:100%;
}
.slide1{
	width:100%;
	justify-content:space-between;
}
.slide1_txt{
	width:50%;
	font-family:'NanumMyeongjo';
	font-weight:800;
	font-size:24px;

}
.slide1_img{
	width:30%;
	box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
	display:flex;
	align-items:center;
	justify-content:center;
}
@keyframes fall{
	0%{
		transform:translateY(0);
	}
	95%{
		transform:translateY(40px);
	}
	100%{
		transform:translateY(45px);
	}
}
.slide2{
	width:100%;
	justify-content:space-between;
}
.slide2_img{
	display:flex;
	align-items:center;
	justify-content:center;
	width:50%;
	transform:translateY(40px);
	box-shadow: 0, 0, rgba(0, 0, 0, 0.09) 0px 8px 4px,0;
	animation:fall 0.5s ease-in;
}
.slide3_img{
	width:500px;
	position:relative;
	height:100px;
}
@keyframes up{
	 to {
    opacity: 1; /* Final state visible */
    transform: translateY(0); /* Final position at original spot */
  }
}
.slide_go{
	margin-top:30px;
	width:100px;
	height:40px;
	border:none;
	border-radius: 5px;
	background: #333;
	color:#fff;
	text-shadow: none;
	display: flex;
	font-family: 'Black han sans';
	font-size:16px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}
.slide3_de{
	border:1px solid black;
	animation:up 1s forwards;
	opacity:0;
	position:absolute;
}
.slide3_de:nth-child(1){
	top:-150px;
	left:0;
}
.slide3_de:nth-child(2){
	top:-50px;
	left:150px;
	animation-delay:0.5s;
}
.slide3_de:nth-child(3){
	top:0px;
	left:50px;
	animation-delay:1s;
}
.slide4{
	width:100%;
	justify-content:space-between;
}
.hero2{
	display:flex;
	align-items:center;
	position:relative;
	justify-content:center;
	width:100%;
	height:auto;
	min-height:300px;
	opacity:0;
	transition: opacity 0.7s ease-out;
}
.effect7{
	transform: translateY(20px); /* 오른쪽에서 왼쪽으로 */
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}
.yearly{
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
}
.scr_b{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
}
.xscroll{
	display:flex;
	flex-direction:row;
	align-items:center;
	width:100%;
	height:60px;
	padding:10px;
	overflow-x:auto;
	overflow-y:hidden;
	white-space: nowrap;
	background:rgba(0,0,0,0.1);
	gap:10px;
}
.xscroll::-webkit-scrollbar{
	height:10px;
}
.xscroll::-webkit-scrollbar-thumb{
	background:#333;
	border-radius:10px;
}
.xscroll::-webkit-scrollbar-button{
	display:none;
}
.new_txt{
	color:red;
	font-size:10px;
}
.prb_box{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	font-family:'inter';
	font-weight:800;
	text-decoration:none;
	width:100px;
	height:60px;
	background:#fff;
	border:2px solid #333;
	border-radius:5px;
}
.prb_box:hover{
	scale:1.05;
	transition:0.2s;
}
.yearly_title{
	font-size:18px;
	color:#333;
}
.yearly_day{
	font-size:12px;
	color:#555;
}
.column1{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	align-items: center;
}
.ds1{
	width:100%;
	max-width:1000px;
	height:300px;
	overflow:hidden;
	position: relative;
	align-items:center;
}
.review_tt{
	position:absolute;
	top:45%;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
}
.review {
  position: absolute;
	width:1500px;
  display: inline-block;
  white-space: nowrap;
}
.review-content{
	display:flex;
	justify-content:space-between;
	align-items:center;
	font-family:'NanumMyeongjo';
	font-size:12px;
}
@keyframes moveLeftToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1500px);
  }
}
@keyframes moveRightToLeft {
  0% {
    transform: translateX(1500px);
  }
  100% {
    transform: translateX(0);
  }
}
.review:nth-child(1) {
	top:70px;
	left:-500px;
	animation: moveLeftToRight 20s linear infinite;
}
.review:nth-child(2) {
	top:180px;
	right:500px;
  animation: moveRightToLeft 20s linear infinite;
}
.review:nth-child(3) {
	top:210px;
	left:-500px;
  animation: moveLeftToRight 20s linear infinite;
}
.review:nth-child(4) {
	top:270px;
	right:500px;
	animation: moveRightToLeft 20s linear infinite;
}
.row1{
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:row;
}
.t1{
	font-size:22px;
	margin-bottom:10px;
}
.t2{
	font-size:19px;
}
.desc{
	text-align:center;
	width:100%;
	line-height:1.2em;
	font-family:'inter';
	font-weight:700;
}
.bx_desc{
	font-size:48px;
	width:100%;
	line-height:1.9em;
	font-family:'staatliches';
	color:#727288;
}
.bx_container{
	gap:10px;
	width:100%;
	justify-content:space-between;
}
.bx{
	width:25%;
	height:200px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	text-decoration:None;
	background:#333;
}
.bx:hover{
	transform:scale(1.05);
	transition:0.2s;
}
.bx_title{
	font-family:'Black han sans';
	font-size:50px;
	color:#fff;
	line-height:1em;
}
.bx_title_desc{
	font-family:'Black han sans';
	font-size:20px;
	color:#fff;
	line-height:0.7em;
}


.last_text{
	width:100%;
	height:300px;
}
.ds3{
	width:100%;
	max-width:1000px;
	justify-content:space-between;
	opacity:0;
	transition: opacity 0.7s ease-out;
}
.effect0{
	transform: translateY(20px);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}
.lt_title{
	height:100%;
	font-family:'NanumMyeongjo';
	font-weight:600;
	font-size:40px;
	line-height:1.2em;
	width:460px;
}
.lt_desc_smal{
	font-family:'NanumMyeongjo';
	font-size:16px;
	line-height:1.5em;
}
.lt_desc{
	font-family:'NanumMyeongjo';
	font-size:16px;
	line-height:1.5em;
}
.faq{
	width:100%;
	margin-top:50px;
	height:auto;
	justify-content:start;

}
.ds4{
	opacity:0;
	width:100%;
	max-width:1000px;
	gap:60px;
	transition: opacity 0.7s ease-out;
}
.effect5{
	transform: translateY(20px);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}
.faq_txt{
	font-family:'inter';
	font-weight:700;
	font-size:50px;
	letter-spacing:-2.6px;
	line-height:1.2em;
}
.arcodion{
	font-family:'inter';
	width:100%;
	font-size:16px;
	font-weight:600;
	line-height:1.5em;
	background:#F2F2F2;
	border-radius:20px;
}
.arc{
	padding:20px 0 20px 40px;
	transition: 0.4s;
}
.arc_txt{
	color:#666666;
	padding:0 20px 20px 40px;
	transition: max-height 0.2s ease-out;
}
.contact{
	opacity:0;
	width:100%;
	height:500px;
	padding-bottom:100px;
	gap:60px;
	transition: opacity 0.7s ease-out;
}
.effect6{
	transform: translateY(20px);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}
.visible {
	opacity: 1;
	transform: translateY(0);
}
.cont_txt{
	font-family:'inter';
	font-weight:700;
	font-size:50px;
	line-height:1.2em;
	letter-spacing:-2.1px;
}
.cont_form{
	width:100%;
	max-width:540px;
	gap:15px;
}
.ipt{
	width:510px;
	padding:15px;
	border-radius:8px;
	background:#F2F2F2;
	border:None;
	font-family:'inter';
	font-size:16px;
}
.send{
	width:100%;
	padding:15px;
	border:none;
	border-radius:8px;
	color:#fff;
	font-family:'inter';
	font-size:16px;
}

@media(max-width:1200px){
	.ds1{
		max-width:810px;
	}
	.m_nav{
		max-width:810px;
	}
	.ds2{
		max-width:810px;
	}
	.ds3{
		max-width:810px;
		flex-direction:column;
		gap:20px;
		align-items:start;
	}
	.lt_desc{
		width:810px;
	}
	.ds4{
		max-width:810px;
	}
}
@media(max-width:810px){
	.hero2{
		min-height:250px;
		margin-bottom:50px;
	}
	.hero{
		margin-top:50px;
	}
	.prb_item{
		width:150px;
		height:150px;
		font-size:30px;
	}
	.prb_item_txt{
		width:150px;
		font-size:16px;
	}
	.arcodion{
		font-size:14px;
	}
	.ft_desc{
		font-size:14px;
	}
	.ft_title{
		font-size:30px;
	}
	.m_nav{
		width:90%;
	}
	.ds1{
		width:90%;
	}
	.ds2{
		width:80%;
		gap:20px;
	}
	.img_div{
		width:100%;
		height:200px;
	}
	.ft_content{
		width:100%;
		gap:10px;
	}
	.ds3{
		width:90%;
	}
	.send{
		width:90%;
	}
	.ds4{
		width:90%;
	}
	.lt_desc_smal{
		width:100%;
		font-size:12px;
	}
	.lt_desc{
		width:100%;
		font-size:14px;
	}
	.cont_form{
		width:90%;
	}
	.ipt{
		width:80%;
	}

	.contact{
		gap:30px;
	}
	.lt_title{
		width:100%;
		font-size:25px;
	}
	.t1{
		font-size:16px;
	}
	.t2{
		font-size:14px;
	}
	.bx_container{
		display:grid;
		grid-template-columns: 1fr 1fr;
	}
	.bx{
		width:100%;
		height:150px;
	}
	.bx_title{
		font-size:40px;
	}
	.bx_desc{
		font-size:30px;
	}
	.slide1{
		flex-direction:column-reverse;
		gap:40px;
	}
	.slide1_txt{
		width:95%;
		font-size:18px;
	}
	.slide1_img{
		width:200px;
	}
	.slide2{
		flex-direction:column-reverse;
	}
	.slide3_img{
		width:95%;
	}
	.slide3_de{
		width:100px;
	}
	.slide3_de:nth-child(1){
		top:-110px;
		left:80px;
	}
	.slide3_de:nth-child(2){
		top:-70px;
		left:160px;
	}
	.slide3_de:nth-child(3){
		top:-50px;
		left:110px;
	}
	.slide4{
		flex-direction:column-reverse;
		gap:10px;
	}
}