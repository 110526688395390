.nav_bx{
	width:100%;
	height:70px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction: column;
	position:fixed;
	top:0;
	z-index:10000;
	background:#fff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.title{
	font-family:'Black han sans';
	font-size:30px;
	letter-spacing:-0.07em;
	text-align:center;
	max-width:500px;
	font-weight:200;
	text-decoration: none;
	margin-right: 10px;
}
.nav_top{
	width:100%;
	max-width: 1000px;
	display: flex;
	align-items: center;
	justify-content: end;
	gap:20px;
}
.nav_ct{
	width:100%;
	height:50px;
	max-width:1000px;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.nav_link{
	text-decoration: none;
	font-size:12px;
	color:#666;
}
.nav_cd{
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
}
.nav_credit{
	width:16px;
}
.lg{
	display:flex;
	height: 100%;
	font-family:'inter';
	gap:10px;
	align-items: center;
	text-align:left;
	flex-grow:1;
}
.lg_txt{
	font-size:16px;
	font-family:'inter';
	text-decoration:none;
	color:#000;
	height:50px;
	display:flex;
	align-items:center;
	justify-content:center;
}
.cr_txt{
	font-size:16px;
	font-family:'inter';
	text-decoration:none;
	color:#000;
}
.lg_txt:hover{
	scale:1.1;
	transition:0.2s;
}
.nav_tt{
	width:120px;
	text-align:center;
	position:relative;
	padding:5px;
}
.hv_dpn{
	top:50px;
	left:-5px;
	position:absolute;
	width:150px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	flex-direction:column;
	border-radius:5px;
}
.dpn_txt{
	width:100%;
	font-size:14px;
	text-decoration:none;
	color:#000;
	height:30px;
	font-family:'inter';
	border-radius:5px;
	background:#fff;
	display:flex;
	align-items:center;
	justify-content:center;
}
.new_tag{
	color:red;
	position: absolute;
	font-size:10px;
}
.nav_tt .new_tag{
	top:10px;
	left:15px;
}
.side_tt .new_tag{
	top:10px;
	left:50px;
}
.nav_bar{
	width:30px;
	height:30px;
	background:url('/img/nav.png');
	background-size:contain;
	background-repeat: no-repeat;
	display:none;
}
.nav_bar_close{
	width:30px;
	height:30px;
	background:url('/img/nav_close.png');
	background-size:contain;
	background-repeat: no-repeat;
	display:none;
}
.dpn_txt:hover{
	background:rgb(230,230,230);
	transition:0.3s;
}
.nav_side{
	display:none;
	width:200px;
	height:100vh;
	background:#fff;
	position:fixed;
	right:0;
	top:71px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	z-index:10000;
}
.side_tt{
	width:100%;
	height:50px;
	display:flex;
	position: relative;
	align-items:center;
	justify-content:space-around;
	font-family:'inter';
	font-size:16px;
	text-decoration:none;
	color:#333;
}
.side_small{
	font-size:12px;
}
@media(max-width:1000px){
	.lg{
		margin-left:10px;
	}
	.nav_tt{
		width:60px;
		padding:5px;
	}
	.hv_dpn{
		width:60px;
	}
	.dpn_txt{
		font-size:11px;
		border-radius:0;
	}
	.nav_credit{
		width:11px;
	}
	.lg_txt{
		font-size:14px;
	}
	.cr_txt{
		font-size:11px;
	}
}
@media(max-width:500px){
	.title{
		margin-left:10px;
	}
	.lg{
		display:none;
	}
	.nav_tt{
		display:none;
	}
	.nav_ct{
		justify-content:space-between;
	}
	.nav_bar{
		display:block;
		margin-right:10px;
	}
	.nav_bar_close{
		display:block;
		margin-right:10px;
	}
}