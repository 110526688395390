.make_tct{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height:100vh;
}

.make_ct{
  width:800px;
	height:400px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border:1px solid rgba(218, 218, 218, 0.8);
	border-radius:10px;
	display:flex;
	justify-content:space-around;
	flex-direction:column;
  align-items: center;
}
.make_lst{
  width:90%;
  display: flex;
}
.make_btn{
  width:80px;
  height:40px;
  border-radius: 10px;
  background: #333;
  color:#fff;
  font-family: 'Black han sans';
  display: flex;
  align-items: center;
  justify-content: center;
}
.make_btn:hover{
  cursor:pointer;
}
.make_title{
  font-family: 'Black han sans';
  font-size:18px;
  display: flex;
  align-items: center;
  width:100px;
}
.make_year{
  margin-left:30px;
  display: flex;
  gap:20px;
  align-items: center;
}
.make_counts{
  margin-left:30px;
  display: flex;
  gap:10px;
  align-items: center;
}
.make_fr{
  margin-left: 30px;
}
.make_category{
  margin-left: 30px;
  display: flex;
  gap:30px;
}
.items_ct{
  display: flex;
  width:300px;
  flex-wrap: wrap;
  gap:10px;
}
.make_diff{
  display: flex;
}
.make_items{
  font-size:12px;
  padding:3px;
  height:18px;
  display: flex;
  gap:10px;
  justify-content: space-around;
  border:1px solid black;
  border-radius: 10px;
}
.range-slider__range{
  margin-left: 30px;
  -webkit-appearance: none;
  width: 200px;
  height: 6px; /* 슬라이더 바 두께 */
  background: #ddd; /* 채워지는 색 제거하고 기본 바 색 지정 */
  border-radius: 5px;
  outline: none; /* 포커스 시 외곽선 제거 */
  cursor: pointer;
}
.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none; /* 기본 브라우저 스타일 제거 */
  width: 20px;
  height: 20px;
  background: #333; /* 슬라이더 점 색상 */
  border-radius: 50%; /* 원형 모양 */
  cursor: pointer;
  border: 2px solid #fff; /* 점 외곽 경계선 */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* 점 그림자 */
}
.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.range-slider__range::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 40px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #333;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid #333;
    border-bottom: 7px solid transparent;
    content: '';
  }
}
.range_desc{
  width:200px;
  margin-left:20px;
  font-size:12px;
}

@media(max-width:800px){
  .make_ct{
    width:95%;
    height: auto;
    min-height: 400px;
  }
  .make_category{
    flex-direction: column;
  }
  .items_ct{
    width:80%;
  }
  .range-slider__range{
    width:50%;
  }
  .make_diff{
    flex-direction: column;
    align-items: center;
  }
  .make_title{
    width:70px;
  }
}