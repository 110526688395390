.pst{
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
}
.pst_ct{
	width:95%;
	max-width:1000px;
	margin-top:80px;
}
.pst_tt{
	margin-top:20px;
	font-size:36px;
	font-family:'black han sans';
}
.pst_detail{
	display:flex;
	gap:10px;
	font-family:'inter';
	align-items:center;
}
.pst_usr{
	font-size:16px;
	font-weight:800;
}
.pst_upt{
	font-size:12px;
}
.bar{
	color:rgb(200,200,200);
}
.dvd_bar{
	width:100%;
	border:1px solid rgb(200,200,200);
	margin-top:10px;
	margin-bottom:10px;
}
.cmt{
	width:100%;
	padding:10px;
	border-radius: 10px;
	background:rgba(240,240,240);
	display:flex;
	margin-bottom:10px;
	position:relative;
}
.cmt_user_name{
	width:100px;
}
.cmt_content{
	width:700px;
}
.cmt_updated_at{
	width:180px;
	text-align:right;
}
.cmtform{
	width:100%;
	resize:none;
	font-size:16px;
	height:60px;
}
.cmtsbm{
	padding:5px 10px 5px 10px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:2px;
}
.usbtn{
	margin-right:5px;
}
.cmtsbm:hover{
	cursor:pointer;
}
.cmtdel{
	font-size:12px;
	position:absolute;
	top:5px;
	right:5px;
}
.cmtdel:hover{
	cursor:pointer;
}
@media(max-width:1000px){
	.pst_tt{
		font-size:20px;
	}
	.cmt{
		padding:0;
		font-size:12px;
	}
	.cmt_user_name{
		flex-grow:1;
		display:flex;
		align-items:center;
		margin-left:5px;
	}
	.cmt_content{
		flex-grow:7;
		display:flex;
		align-items:center;
	}
	.cmt_updated_at{
		flex-grow:2;
		display:flex;
		align-items:center;
		margin-right:5px;
	}
}